<template>
  <div class="addPromotionbox">
    <div class="titlebox">
      <span class="marginleft10" @click="toPromotionManagement"
        ><i class="el-icon-arrow-left"></i>促销管理</span
      >
      <span> - 新建促销</span>
    </div>
    <div class="borderbottom">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        active-text-color="#256DEF"
      >
        <el-menu-item index="1">携程</el-menu-item>
        <el-menu-item index="2">艺龙</el-menu-item>
      </el-menu>
    </div>
    <div>
      <div class="mainbox">
        <div class="mainleftbox">
          <img src="../../assets/imgs/wuyefang.png" alt="" />
        </div>
        <div class="mainmidbox">
          <div>
            <span class="maintitle">午夜特惠</span>
            <div class="hotbox">Hot</div>
            <div class="newbox">New</div>
          </div>
          <div class="mainlabelbox">
            抓住本地客人凌晨入住需求，设置特价，每晚24:00~次日6:00开售!
          </div>
        </div>
        <div class="mainrightbox">
          <el-button type="primary" @click="toAddMidnightPromote" size="mini">马上创建</el-button>
        </div>
      </div>
      <div class="mainbox">
        <div class="mainleftbox">
          <img src="../../assets/imgs/jinyeshuaimai.png" alt="" />
        </div>
        <div class="mainmidbox">
          <div>
            <span class="maintitle">今夜甩卖</span>
            <div class="hotbox">Hot</div>
          </div>
          <div class="mainlabelbox">
            尾房变现，抓住最后客源。设置特价，每晚16:00~次日6:00开售!
          </div>
        </div>
        <div class="mainrightbox">
          <el-button type="primary" @click="toAddTonightPromote" size="mini">马上创建</el-button>
        </div>
      </div>
      <div class="mainbox">
        <div class="mainleftbox">
          <img src="../../assets/imgs/tehuichuxing.png" alt="" />
        </div>
        <div class="mainmidbox">
          <div>
            <span class="maintitle">出行特惠</span>

            <div class="newbox">New</div>
          </div>
          <div class="mainlabelbox">
            将您的酒店精准推荐给曾经在携程预订过机票/火车票/门票/汽车票/用车/船票客人
          </div>
        </div>
        <div class="mainrightbox">
          <el-button type="primary" size="mini" @click="toAddTravelPromote">马上创建</el-button>
        </div>
      </div>
      <div class="mainbox">
        <div class="mainleftbox">
          <img src="../../assets/imgs/mendianlijian.png" alt="" />
        </div>
        <div class="mainmidbox">
          <div>
            <span class="maintitle">门店新客</span>
          </div>
          <div class="mainlabelbox">
            新客拓展，间夜猛提升;酒店新客享受立减，折扣由你来定!
          </div>
        </div>
        <div class="mainrightbox">
          <el-button type="primary" @click="toAddNewGuestPromote" size="mini">马上创建</el-button>
        </div>
      </div>
      <div class="mainbox">
        <div class="mainleftbox">
          <img src="../../assets/imgs/tiantiantejia.png" alt="" />
        </div>
        <div class="mainmidbox">
          <div>
            <span class="maintitle">天天特价</span>
            <div class="hotbox">Hot</div>
          </div>
          <div class="mainlabelbox">
            特价天天有，竞争力倍增。所有客人可享特价，快速降价一步到位!
          </div>
        </div>
        <div class="mainrightbox">
          <el-button type="primary" @click="toAddSagawaPromote" size="mini">马上创建</el-button>
        </div>
      </div>
      <div class="mainbox">
        <div class="mainleftbox">
          <img src="../../assets/imgs/lianzhuyouhui.png" alt="" />
        </div>
        <div class="mainmidbox">
          <div>
            <span class="maintitle">连住特惠</span>

            <div class="newbox">New</div>
          </div>
          <div class="mainlabelbox">
            为入住天数较多的客人提供折扣优惠，刺激用户下单!
          </div>
        </div>
        <div class="mainrightbox">
          <el-button type="primary" @click="toAddContinuousPromote" size="mini">马上创建</el-button>
        </div>
      </div>
      <div class="mainbox">
        <div class="mainleftbox">
          <img src="../../assets/imgs/tiqianyuding.png" alt="" />
        </div>
        <div class="mainmidbox">
          <div>
            <span class="maintitle">提前预定</span>

            <div class="newbox">New</div>
          </div>
          <div class="mainlabelbox">
            合理调控房态，需要客人提前一定时间预订才能享受优惠!
          </div>
        </div>
        <div class="mainrightbox">
          <el-button type="primary" @click="toAddAdvanceReservePromote" size="mini">马上创建</el-button>
        </div>
      </div>
      <div class="mainbox">
        <div class="mainleftbox">
          <img src="../../assets/imgs/xianshiqianggou.png" alt="" />
        </div>
        <div class="mainmidbox">
          <div>
            <span class="maintitle">限时抢购</span>

            <div class="newbox">New</div>
          </div>
          <div class="mainlabelbox">
            限时惊爆价，可设置特定时间和特定折扣优惠，灵活控价!
          </div>
        </div>
        <div class="mainrightbox">
          <el-button type="primary" @click="toAddFlashSalePromote" size="mini">马上创建</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
    };
  },
  methods: {
    handleSelect(key, keyPath) {},
    //回到促销管理
    toPromotionManagement() {
      this.$router.push({
        path: "/promotionManagement",
      });
    },
    //去新建午夜特惠页面
    toAddMidnightPromote(){
      this.$router.push({
        path:'/addMidnightPromote',
        query:{
          promoteName:'午夜特惠',
          promoteCode:'213'
        }

      })
    },
    //去新建今夜甩卖页面
    toAddTonightPromote(){
      this.$router.push({
       // path:'/addTonightPromote',
        path:'/addMidnightPromote',
         query:{
          promoteName:'今夜甩卖',
          promoteCode:'103'
        }
      })
    },
    //去新建出行特惠页面
    toAddTravelPromote(){
      this.$router.push({
        //path:'/addTravelPromote'
         path:'/addMidnightPromote',
         query:{
          promoteName:'出行特惠',
          promoteCode:'178'
        }

      })
    },
    //去新建门店新客页面
    toAddNewGuestPromote(){
      this.$router.push({
       // path:'/addNewGuestPromote'
        path:'/addMidnightPromote',
         query:{
          promoteName:'门店新客',
          promoteCode:'123'
        }
      })
    },
    //去新建天天特价页面
    toAddSagawaPromote(){
      this.$router.push({
       // path:'/addSagawaPromote'
        path:'/addMidnightPromote',
         query:{
          promoteName:'天天特价',
          promoteCode:'104'
        }

      })
    },
    //去新建连住特惠页面
    toAddContinuousPromote(){
      this.$router.push({
       // path:'/addContinuousPromote'
         path:'/addMidnightPromote',
         query:{
          promoteName:'连住特惠',
          promoteCode:'107'
        }
      })
    },
    //去新建提前预定页面
    toAddAdvanceReservePromote(){
      this.$router.push({
       // path:'/addAdvanceReservePromote'
        path:'/addMidnightPromote',
         query:{
          promoteName:'提前预定',
          promoteCode:'106'
        }
      })
    },
    //去新建限时抢购页面
    toAddFlashSalePromote(){
      this.$router.push({
        //path:'/addFlashSalePromote'
         path:'/addMidnightPromote',
         query:{
          promoteName:'限时抢购',
          promoteCode:'102'
        }
      })
    },

  },
};
</script>

<style lang="less" scoped>
.addPromotionbox {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(220, 228, 240, 0.42);
  border-radius: 10px;
  padding-bottom: 20px;
}
.titlebox {
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #256def;
  border-radius: 10px 10px 0px 0px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #fafcff;
}
.marginleft10 {
  margin-left: 10px;
  cursor: pointer;
}
.el-menu--horizontal > .el-menu-item {
  height: 40px;
  line-height: 40px;
}
.mainbox {
  margin: 0 auto;
  margin-top: 20px;
  width: 1180px;
  height: 100px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
}
.borderbottom {
  border-bottom: 1px solid #eeeeee;
}
.mainleftbox {
  display: inline-block;
  margin-left: 40px;
  vertical-align: top;
  margin-top: 18px;
  img {
    width: 64px;
    height: 64px;
  }
}
.mainmidbox {
  display: inline-block;
  margin-left: 40px;
  vertical-align: top;
  margin-top: 31px;
  width: 900px;
}
.maintitle {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.hotbox {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  width: 30px;
  height: 16px;
  background: #ff504d;
  border-radius: 3px;
  text-align: center;
  color: #ffffff;
}
.newbox {
  display: inline-block;
  vertical-align: top;
  margin-left: 10px;
  width: 36px;
  height: 16px;
  background: #47b267;
  border-radius: 3px;
  text-align: center;
  color: #ffffff;
}
.mainlabelbox {
  margin-top: 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #888f98;
}
.mainrightbox {
  display: inline-block;
  vertical-align: top;
  margin-top: 35px;
}
</style>>

